import React from 'react'
import Slidertatatea from '../inc/Slidertatatea'
import Tata from "../pages/Tata";
import Navbar from '../inc/Navbar';
import ScrollToTop from "react-scroll-to-top";
import {Link} from "react-router-dom";

function Tatapage() {
  return (
    <div>
          <Navbar />
          <br />
      <br />
     

      <ScrollToTop smooth top="100" color="Darkblue" />
        <Slidertatatea/>
        <div className="album py-1">
        
        <div className="container">

            <div className="row">
<nav aria-label="breadcrumb">
<ol className="breadcrumb">
<li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
<path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
<path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Back</Link></li>

<li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Dukes">Dukes</Link></li>
<li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/Hunumanta" href="#">Hunumanta</Link></li>
<li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="#" href="#">Dalmia</Link></li>
<li style={{color:"maroon"}} className="breadcrumb-item"><Link style={{color:"maroon"}} to="/contact" href="#">Contact</Link></li>
<li className="breadcrumb-item active" aria-current="page">Tata Consumer Products</li>

</ol>
</nav>
</div>
</div>
</div>
        <Tata/>

    </div>
  )
}

export default Tatapage