

import React from 'react'
import Navbar from "../inc/Navbar";
import Slider from "../inc/Slider";
import { Typewriter } from 'react-simple-typewriter'
import {Link} from "react-router-dom";
import ReactWhatsapp from "react-whatsapp";
import fmcg from "../images/FMCG.jpg";
import Agricultural from "../images/AGRI_MAIN.jpg";
import Map from "../pages/Map";

// import Fmcg from "../pages/Fmcg";

import { 
  FacebookShareButton, WhatsappShareButton, WhatsappIcon,
   FacebookIcon, TwitterShareButton,   EmailShareButton,   EmailIcon,

   LinkedinShareButton,
   LinkedinIcon,
   TwitterIcon,
}  from "react-share";
import Food from './Food';
import Tata from './Tata';

import AddBook from '../Addbook';
import Slidertatatea from '../inc/Slidertatatea';

function Home() {
  return (
    <div>
      <Navbar />

      <br />
      
      <div className="album py-1">

        <div className="container">

          <div className="row">
            <div className="col-md-8">



              <h6> <small> Even Traders Pvt Ltd</small></h6>


            </div>



          </div>
        </div>
      </div>
      <div style={{color: "Green", margin: "10px" }} className="fixed-bottom"> 
      <div className="d-flex justify-content-end">
      <ReactWhatsapp number="+91  9593481949" class="btn btn-success" message="I am Interested"> <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25"  fill="currentColor" class="bi bi-whatsapp"  viewBox="0 0 16 16">
  <path d="M13.601 2.326A7.854 7.854 0 0 0 7.994 0C3.627 0 .068 3.558.064 7.926c0 1.399.366 2.76 1.057 3.965L0 16l4.204-1.102a7.933 7.933 0 0 0 3.79.965h.004c4.368 0 7.926-3.558 7.93-7.93A7.898 7.898 0 0 0 13.6 2.326zM7.994 14.521a6.573 6.573 0 0 1-3.356-.92l-.24-.144-2.494.654.666-2.433-.156-.251a6.56 6.56 0 0 1-1.007-3.505c0-3.626 2.957-6.584 6.591-6.584a6.56 6.56 0 0 1 4.66 1.931 6.557 6.557 0 0 1 1.928 4.66c-.004 3.639-2.961 6.592-6.592 6.592zm3.615-4.934c-.197-.099-1.17-.578-1.353-.646-.182-.065-.315-.099-.445.099-.133.197-.513.646-.627.775-.114.133-.232.148-.43.05-.197-.1-.836-.308-1.592-.985-.59-.525-.985-1.175-1.103-1.372-.114-.198-.011-.304.088-.403.087-.088.197-.232.296-.346.1-.114.133-.198.198-.33.065-.134.034-.248-.015-.347-.05-.099-.445-1.076-.612-1.47-.16-.389-.323-.335-.445-.34-.114-.007-.247-.007-.38-.007a.729.729 0 0 0-.529.247c-.182.198-.691.677-.691 1.654 0 .977.71 1.916.81 2.049.098.133 1.394 2.132 3.383 2.992.47.205.84.326 1.129.418.475.152.904.129 1.246.08.38-.058 1.171-.48 1.338-.943.164-.464.164-.86.114-.943-.049-.084-.182-.133-.38-.232z"/>
</svg> Whatsapp  <div className="spinner-grow text-light spinner-grow-sm"  role="status">
              <span className="visually-hidden"> Loading...</span>
            </div></ReactWhatsapp></div>
            </div>
      <Slider />
      <center><h1 style={{ color: "Darkblue" }} ><b>Evan Traders Pvt Ltd</b> </h1>
        <p style={{ color: "Darkblue" }} >Agriculture & FMGC </p>
      </center>

      <div className="p-1 mb-0 bg-dark text-white">

        <div className="album py-0">

          <div className="container">

            <div className="row">
              <div className="col-md-7">

                <div className="card-body">

                  <h1 className="card-text mb-2" style={{ paddingTop: "1rem", margin: "auto 0", color: "Gray" }}> <b>  <span style={{ color: "white", fontWeight: "bold", marginleft: '7px' }}>
                    <Typewriter
                      loop
                      cursor
                      cursorStyle="_"
                      typeSpeed={90}
                      deleteSpeed={50}
                      delaySpeed={1000}
                      words={[" AGRICULTURE", " FMCG "]}
                    />
                  </span>
                  </b></h1>

                  <p className="card-text mb-0">Fast Services, Mission Big, High quality premium products</p>

                </div>


              </div>
            </div>
          </div>
        </div>

      </div>


      <div className="album py-4">
        
        <div className="container">

            <div className="row">
       
            <div className="col-md-6 mt-8" >
                        <div className="card mb-4 border-White box shadow">
                          <Link to="/Dukes">
                        <img src={fmcg} className="card-img-top"   alt="fmcg_evan_traders" />
                        </Link>
                            <div className="card-body">
                                
                                <h6 className="card-text"> <b>Food Manufacturing Consumer Goods</b></h6>
                                <p className="card-text">Even after doing web pages, we are on the 24X7 side</p>
                                
                                <button type="button" className="btn btn btn-dark me-2">
                                       Share <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button> 
                                        
                                        < FacebookShareButton url="https://www.evantraders.com/Dukes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.evantraders.com/Dukes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.evantraders.com/Dukes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.evantraders.com/Dukes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.evantraders.com/Dukes" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
                                <div className="d-flex justify-content-between align-items-center">
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            <div className="col-md-6 mt-6" >
                        <div className="card mb-4 border-white box shadow">
                          <Link to="/Poultry">
                        <img src={Agricultural} className="card-img-top"   alt="agricultural_evan_traders" />
                        </Link>
                            <div className="card-body">
                                <h6 className="card-text"><b>Agriculture Product</b></h6>
                                <p className="card-text"> 
Clicking on the content of your website will open the </p>
<button type="button" className="btn btn btn-dark me-2">
                                          Share  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-share-fill" viewBox="0 0 16 16">
  <path d="M11 2.5a2.5 2.5 0 1 1 .603 1.628l-6.718 3.12a2.499 2.499 0 0 1 0 1.504l6.718 3.12a2.5 2.5 0 1 1-.488.876l-6.718-3.12a2.5 2.5 0 1 1 0-3.256l6.718-3.12A2.5 2.5 0 0 1 11 2.5z"/>
</svg>
                                        </button>
                                        < FacebookShareButton url="https://www.evantraders.com/Poultry" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <FacebookIcon size={37} round={true}/>
</FacebookShareButton>

 <  WhatsappShareButton url="https://www.evantraders.com/Poultry" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <WhatsappIcon size={37} round={true}/>
</ WhatsappShareButton>

                              < TwitterShareButton url="https://www.evantraders.com/Poultry" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <TwitterIcon size={37} round={true}/>
</TwitterShareButton>  
<  LinkedinShareButton url="https://www.evantraders.com/Poultry" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  LinkedinIcon size={37} round={true}/>
</ LinkedinShareButton>

<  EmailShareButton url="https://www.evantraders.com/Poultry" className="me-2" quote={"India's Most Iconic Website Developer"} hastag={"#WebApp"}
>
    <  EmailIcon size={37} round={true}/>
</ EmailShareButton>
<div className="d-flex justify-content-between align-items-center">
                                
                                        
                            </div>
                            </div>
                            </div>
                               
                            </div>
                            </div>
                            </div>
                            </div>
                  {/* <Fmcg/> */}
                  <Slidertatatea/>
                  <br/>
                  <Tata/>
                  <Food/>
                  
<AddBook/>
<Map/>
    </div>
  )
}

export default Home
