import React from 'react';
// import {Link} from 'react-router-dom';
import Navbar from '../inc/Navbar';

function Terms (props) {
    return (

        <>
        <Navbar/>
        <br/>
      <br/>
      <br/>
 <div className="album py-5">
        
        <div className="container">

            <div className="row">

            <div className="col-md-30">
                        <div className="card mb-4 box-shadow">
                        <div className="card-body">
                        <h5 className="card-text"  style= {{textAlign: "center"}}><b>Terms and Conditions of Use</b></h5>
                        <br/>
                                <h6 className="card-text"> Welcome to the portal www.evantraders.com, The Application is operated by Evan Traders Private Limited. Registered under India Act 2013 Govt.of India(CIN No. U47810WB2023PTC263477) 
                                 </h6>
                                 <h6>EVAN TRADERS PVT LTD's registered office address is Dakir Mondir, Shikarpur (nadia), Nadia, Karimpur I, West Bengal, India, 741158.</h6>
                                <p className="card-text">Please read these terms of use carefully. By continuing to access and use this application you confirm that you accept our terms and conditions described herein. If you do not agree the terms of use, do not use this application. </p>
                                <p className="card-text">For customers to buy our products online
www.evantraders.com online website updated.  </p>
                                <p className="card-text"><b> 1. Entire Agreement</b></p>
                                <p className="card-text"> I. This Agreement (hereinafter defined) sets out the Terms under which the User(s) (hereinafter defined) is/are permitted to use the Website/Mobile  Application and the Services (hereinafter defined) available thereunder.</p><br/>
                                <p className="card-text"><b>2.Changes</b></p>
                                <p className="card-text"> I. Evan Traders reserves its rights to modify, update, change, edit, alter, amend, revise or substitute (collectively “Revisions”) the Agreement from time to time. Any Revisions made will be effective immediately upon their posting on the Our Website.</p>
                                <p className="card-text">Any material changes will be notified to the User(s) either through the Services, email(s), SMS or in-app push notifications. User(s) are encouraged to review these Terms periodically for any updates or Revisions. User(s) continued use of the services consequent to 
                                any Revisions, shall be deemed acceptance of such Revisions/revised terms and conditions.</p>
                                <p className="card-text"><b>3. Acknowledgement</b></p>
                                <p className="card-text">Before accessing or creating User Account (hereinafter defined) on the Digital Platform, User(s) are advised to carefully read these Terms and the Digital Platform’s Privacy Policy available at www.evantraders.com The Terms and the Privacy Policy are collectively referred to as “Agreement” herein. By accessing/browsing the Website/ Mobile Application for any information and/or availing the Services, the User(s) hereby acknowledge that they have read, understood and accept all the terms and conditions of the Agreement and agree to abide by the same. If the User(s) do not consent or accept the terms and conditions of the Agreement they are advised to forthwith disengage from the Website/Mobile Application and stop the access/use thereof.
            Evan Tradersreserves the right to (i) accept or reject the request of the User(s) to create an account, (ii) terminate the account of registered User(s) for unauthorized use, or (iii) refuse the Services offered through this Digital Platform due to non-availability of Services.
  </p><p className="card-text"><b>4. Eligibility for accessing and availing the available Services on the Digital Platform</b></p>
  <p className="card-text">Any person aged 18 years or above and capable of entering into a contract under the Indian Contract Act, 1872 and subject to acceptance by Evan Tradersis permitted to create/ access User account to avail the available Services. Minors are prohibited from accessing or creating User Account; however Legal Guardian of the Minors can avail the available Services for Minors in accordance with applicable laws. Services are available only for the citizens of India and in the Territory. Users’ may please note that certain Services may be available or limited to only particular area within the Territory</p>
 
<p className="card-text"><b>6. Product</b></p>
<p className="card-text">i. The Registered User(s) may access or avail the following Services</p>
ii. FMCG Products Buy.<br/>
iii. We supply agriculture products -Example - Poultry, Farm, Diary, Fishari<br/>
iv. Health Fitness Services<br/>
v. FMCG Reatil & Wholesale Offline & online sale<br/>
<a href="tel: 9593481949"> Help Line Number - Click Now</a>

                                </div>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        </div>
                        

        </>

        );
    }
    
    export default Terms;