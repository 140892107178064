



import React, { useEffect, useState } from 'react';
import { Link, useNavigate, NavLink } from 'react-router-dom';

import './Navbar.css'
// import { Button,Dropdown} from 'react-bootstrap';
import { Button, Navbar, Dropdown, Container, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap';
import { useUserAuth } from '../../contexts/UserAuthContext'



function Navbars(props) {



  const navigate = useNavigate()
  const { user, logOut } = useUserAuth()
  const [photoURL, setPhotoURL] = useState()
  //const emailVerified = user.emailVerified;
  const handleLogOut = async () => {
    try {
      await logOut()
      window.location.reload()
      navigate("/")
    } catch (err) {

    }
  }

  useEffect(() => {
    if (user?.photoURL) {
      setPhotoURL(user.photoURL)
    }

  }, [user])


  return (
    <>
      {/* <div className="mains container-fluid"> */}
      <div className="fixed-top">
        <Navbar bg="white shadow" expand="lg">
          <Container fluid>
            <Navbar.Brand>
            <Link to="/" className="navbar-brand me-0"><small>Evan Traders</small></Link>
              
            </Navbar.Brand>
            <div className="text-end d-grid gap-0 d-md-flex justify-content-md-start">

{!user ? (

  <div>

    <Link to="/Login">
      <button type="button" className="btn btn-warning btn-sm me-md-1 ">LOGIN</button>
    </Link>
    
  </div>
) :
  <>

    <Dropdown className='dropdown'>
      <Dropdown.Toggle variant="none" id="dropdown-basic">
        <img className='userphoto' src={user.photoURL} alt="" />
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <center>
          <Dropdown.Item>
            <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='info btn-sm'><small>Dashboard</small></Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='primary btn-sm'>Fmcg</Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} variant='success btn-sm'>Upload </Button>
          </Dropdown.Item>
          <Dropdown.Item>
            <Button variant='danger btn-sm' onClick={handleLogOut}><small>LOG OUT</small></Button>
          </Dropdown.Item>
        </center>
      </Dropdown.Menu>

    </Dropdown>
  </>

}

</div>
{/* <Nav.Link className="text"><Link to="/Form"><b style={{ color: "grey" }}>Cart <svg xmlns="http://www.w3.org/2000/svg" width="29" height="29" fill="currentColor" class="bi bi-cart" viewBox="0 0 16 16">
  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
</svg> </b></Link></Nav.Link> */}

<Button onClick={() => { navigate(`/UserDashboard/${user.uid}`) }} className='btn btn-primary btn-sm me-2'>Cart <svg xmlns="http://www.w3.org/2000/svg" width="17" height="17" fill="currentColor" class="bi bi-cart-check-fill" viewBox="0 0 16 16">
  <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z"/>
</svg></Button>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0"
                style={{ maxHeight: '290px' }}
                navbarScroll
              >

<li className="nav-item">
          <Link to="/" className="nav-link active" aria-current="page" href="#">Home</Link>
        </li>
        <li className="nav-item">
          <Link to="/Unicare" className="nav-link " aria-current="page" href="#">Unicare</Link>
        </li>

                
        <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          FMCG Products
                    </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/Dukes" className="dropdown-item" >Dukes</Link></li>
            <li><Link to="/Hunumanta" className="dropdown-item" href="#">Hunumanta</Link></li>
            <li><Link to="/tataproduct" className="dropdown-item" href="#">Tata</Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><Link to="/Dalmia" className="dropdown-item" href="#">Dalmia</Link></li>
          </ul>
        </li>
                <li className="nav-item dropdown">
          <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
          Agriculture

          </a>
          <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to="/Poultry" className="dropdown-item" >Poultry </Link></li>
            <li><Link to="/Duckfarm" className="dropdown-item" > Duck Farming</Link></li>
            <li><Link to="/Dairy" className="dropdown-item" href="#">Diary </Link></li>
            <li><Link to="/Fisheries" className="dropdown-item" href="#">Fisheries </Link></li>
            <li><hr className="dropdown-divider"/></li>
            <li><Link to="/Agriculture" className="dropdown-item" href="#">Innovative Agricultures</Link></li>

            
          </ul>
        </li>
       

{/*             
                <NavDropdown title="FMCG" id="basic-nav-dropdown">
              <NavDropdown.Item href="/Dukes">Dukes</NavDropdown.Item>
              <NavDropdown.Item href="/Hunumanta">
              Hunumanta
              </NavDropdown.Item>
              <NavDropdown.Item href="Dalmia"> Dalmia</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="/tataproduct">
                Tata
              </NavDropdown.Item>
            </NavDropdown>
            <NavDropdown title="Agriculture" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Poltry</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Egg
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Dairy</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Farm
              </NavDropdown.Item>
            </NavDropdown>
                <Nav.Link className="text"> <Link to="/contact"><b style={{ color: "maroon" }}>Contact Us
                </b></Link></Nav.Link> */}
               <li className="nav-item">
          <Link to="/contact" className="nav-link " href="#" tabindex="-1" aria-disabled="true">Contact Us</Link>
        </li>

              </Nav>

              <a href="tel: +91 9732125747" className="btn btn-info mb-2">  Help Line  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-telephone-forward" viewBox="0 0 16 16">
                 <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.568 17.568 0 0 0 4.168 6.608 17.569 17.569 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.678.678 0 0 0-.58-.122l-2.19.547a1.745 1.745 0 0 1-1.657-.459L5.482 8.062a1.745 1.745 0 0 1-.46-1.657l.548-2.19a.678.678 0 0 0-.122-.58L3.654 1.328zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.678.678 0 0 0 .178.643l2.457 2.457a.678.678 0 0 0 .644.178l2.189-.547a1.745 1.745 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.634 18.634 0 0 1-7.01-4.42 18.634 18.634 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877L1.885.511zm10.762.135a.5.5 0 0 1 .708 0l2.5 2.5a.5.5 0 0 1 0 .708l-2.5 2.5a.5.5 0 0 1-.708-.708L14.293 4H9.5a.5.5 0 0 1 0-1h4.793l-1.647-1.646a.5.5 0 0 1 0-.708z" />
              </svg> +91 9732125747</a>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

    </>

  );
}

export default Navbars;

