import React from 'react';

import Navbar from '../inc/Navbar';

function Refund (props) {
    return (

        <>
        <Navbar/>
        <br/>
      <br/>
      <br/>
<div className="album py-5">
        
        <div className="container">

        <div className="row">

        <div className="col-md-30">
                    <div className="card mb-4 box-shadow">
                    <div className="card-body">
                    <h5 className="card-text"  style= {{textAlign: "center"}}><b>Cancellation & Refund</b></h5>
                    <br/>
                            <h6 className="card-text"> Evan Traders Private Limited 
                             </h6>
                             <p className="card-text"><b>1. Return/ Refund Policy</b></p>
<p className="card-text">i. For Online FMCG Product Sell<br/>
</p>

<p className="card-text"><b> 2. Terms of return/refund </b></p>
<p className="card-text">i. Payment will be refunded within 15 days if the product is not delivered due to any reason after booking the product.<br/>
ii. After making the online payment on our website, if the payment is not successful even after your payment is cleared, the payment will be refunded after verification.


 </p>
</div></div>
</div>
</div>
</div>




</div>
                            
        </>

);
}

export default Refund;