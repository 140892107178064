import React from 'react';

import Navbar from '../inc/Navbar';

function Shipping (props) {
    return (

        <>
        <Navbar/>
        <br/>
      <br/>
      <br/>
<div className="album py-5">
        
        <div className="container">

        <div className="row">

        <div className="col-md-30">
                    <div className="card mb-4 box-shadow">
                    <div className="card-body">
                    <h5 className="card-text"  style= {{textAlign: "center"}}><b>Shipping & Delivery</b></h5>
                    <br/>
                            <h6 className="card-text"> Even Traders Private Limited 
                             </h6>
                             <p className="card-text"><b>1. shipping / Delivery</b></p>
<p className="card-text">i. There will be no shipping charges within 5 km of our office</p>
<p className="card-text">  ii. Big advantage - If there is a defect in the corner of the product, the product will be changed on the spot.</p>
<p>  iii. Shipping & Delivery Page will be updateed soon.. </p>

</div></div>
</div>
</div>
</div>




</div>
                            
        </>

);
}

export default Shipping;