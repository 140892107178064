



import { initializeApp } from "firebase/app";
import { getAuth } from 'firebase/auth';
import "firebase/firestore";
import { getFirestore } from "firebase/firestore";
import { getStorage } from 'firebase/storage'
import "firebase/storage";



const firebaseConfig = {
  apiKey: "AIzaSyD_nM686a19pnwN4bxX8hmcbYV90Hz6OsI",
  authDomain: "evan-traders-94bc0.firebaseapp.com",
  projectId: "evan-traders-94bc0",
  storageBucket: "evan-traders-94bc0.appspot.com",
  messagingSenderId: "97420634105",
  appId: "1:97420634105:web:27298a5a3095c1eb733f28",
  measurementId: "G-XJ4SKCLDQ6"
 
};
//init firebase app
const app = initializeApp(firebaseConfig);
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);




export default app;



