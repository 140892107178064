
import React, { useState, useEffect } from 'react';
import Navbar from '../inc/Navbar';
import AddToCartButton from './AddToCart';
import {SubmitForm} from './submit'
import ReactWhatsapp from "react-whatsapp";
import AddBook from '../Addbook';

// import PaginationOld from './PaginationOld';
// import './Mobile.css';
import { Link } from 'react-router-dom';
import ScrollToTop from "react-scroll-to-top";

import { collection, getDocs, } from "firebase/firestore";
import { db } from '../../firebase'
import { useUserAuth } from '../../contexts/UserAuthContext'
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'

firebase.initializeApp({
  apiKey: "AIzaSyD_nM686a19pnwN4bxX8hmcbYV90Hz6OsI",
  authDomain: "evan-traders-94bc0.firebaseapp.com",
  projectId: "evan-traders-94bc0",
  storageBucket: "evan-traders-94bc0.appspot.com",
  messagingSenderId: "97420634105",
  appId: "1:97420634105:web:27298a5a3095c1eb733f28",
  measurementId: "G-XJ4SKCLDQ6"
})
const auth1 = firebase.auth()
const firestore = firebase.firestore()


function Dalmia(props) {

  const [showPerPage, setShowPerPage] = useState(51);
  const [pagination, setPagination] = useState({
    start: 0,
    end: showPerPage,
  });

  const onPaginationChange = (start, end) => {
    setPagination({ start: start, end: end });
  };

  const [searchTerm, setsearchTerm] = useState("");
  const [dalmias, setDalmias] = useState([])
  const dalmiaRef = collection(db, "Dalmia")
  const [cartItemCount, setCartItemCount] = useState(1);


  const [checkups, setCheckups] = useState([])
  const checkup1Ref = collection(db, "Checkups")


const addToCart = () => {
  setCartItemCount(cartItemCount + 1);
};
const bookingDetails=(bookings) =>{
  console.log(bookings)
}
const removeFromCart = () => {
  if (cartItemCount > 0) {
    setCartItemCount(cartItemCount - 1);
  }
};
  useEffect(() => {
    const getdalmias = async () => {
      const data = await getDocs(dalmiaRef);
      setDalmias(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    getdalmias()
  }, [])
  const {
    state: { cart },
    dispatch,
  } = useUserAuth();

  useEffect(() => {
    const getcheckups = async () => {
      const data = await getDocs(checkup1Ref);
      setCheckups(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    getcheckups()
  }, [])

  // const products = [...Array(2)].map(() => ({
  //   name: {props.testn1},
  //   price: price,
  // }));
  // const [state, dispatch] = useReducer(cartReducer, {
  //   academybooks: labtestRef,
  //   cart: [],
  // });

  return (
    <div >

      <Navbar />
      <br />
      <br />
      <br />
      

      <ScrollToTop smooth top="100" color="Darkblue" />
  
      <div className="album py-2">
        
            <div className="container">

                <div className="row">
<nav aria-label="breadcrumb">
  <ol className="breadcrumb">
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/" > <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chevron-double-left" viewBox="0 0 16 16">
  <path fill-rule="evenodd" d="M8.354 1.646a.5.5 0 0 1 0 .708L2.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
  <path fill-rule="evenodd" d="M12.354 1.646a.5.5 0 0 1 0 .708L6.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z"/>
</svg> Back</Link></li>

    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/Dukes" href="#">Dukes</Link></li>
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/Hunumanta" href="#">Hunumanta</Link></li>
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/tataproduct" href="#">Tata Consumer Products</Link></li>
    <li style={{color:"Darkblue"}} className="breadcrumb-item"><Link style={{color:"Darkblue"}} to="/contact" href="#">Contact</Link></li>
    <li className="breadcrumb-item active" aria-current="page">Dalmia </li>
    
  </ol>
</nav>
</div>
</div>
</div>
      <div className="album py-1">

<div className="container">

  <div className="row">
    <div className="col-md-9 ">

      <div className="card-body">
        <h3 style={{color:"maroon"}} className="card-text mb-1"><b>Dalmia Products</b></h3>

      </div>
    </div>
  </div>
</div>
</div>
      <div className="album py-1">

        <div className="container">

          <div className="row">
            <div className="col-md-4">

              <div className="card-body">
                <input type="text" class="form-control" placeholder="Search..." name="location"
                  onChange={(e) => {
                    setsearchTerm(e.target.value);
                  }}
                />
                <div className="d-flex justify-content-between align-items-center">




                </div>


              </div>


            </div>


          </div>
        </div>
      </div>
      
      <div className="album py-2">

        <div className="container">

          <div class="row">
            {dalmias.filter((val) => {
              if (setsearchTerm === "") {
                return val;
              } else if (
                val.name.toLowerCase().includes(searchTerm.toLowerCase()) 
            
              ) {
                return val;
              }
            }).slice(pagination.start, pagination.end).map((labtest) => {
              return (
                <div className="col-md-4" key={labtest.id}>

                  <div className="card  mb-4 border-dark box shadow ">
                    <img src={labtest.img} alt="academy" />
                    <div className="card-body">
                      <h5 style={{color:"maroon"}} className="card-text">{labtest.name}</h5>
                      <p className="card-text">{labtest.detail}</p>
                      <p style={{color: "Gray"}}className="card-text mb-2"> M.R.P.: <del> ₹{labtest.mrp}</del> </p>
                      <button className='btn btn-outline-danger btn-sm mb-2'>{labtest.off} %OFF</button>
                      <h6 className="card-text" >₹ {labtest.price}   </h6>
                      {/* <h6 className="card-text" >ADD TO CART</h6> */}
                      {/* <h6 className="card-text" >{labtest.type}</h6> */}
                      <div className="d-flex justify-content-between align-items-center">


<a className="btn btn-warning btn-sm mb-2" href="tel: 9593481949"> Call Book Now </a>
<ReactWhatsapp number="+91 9593481949" className="btn btn-success btn-sm mb-2" message="I am Interested"> Whatsapp Book</ReactWhatsapp>

</div>
                      <div className="d-flex justify-content-between align-items-center">
                        {cart.some((p) => p.id === labtest.id) ? (
                          <div className="App">
                          <AddToCartButton
                            itemCount={cartItemCount}
                            onIncrement={addToCart}
                            onDecrement={removeFromCart}
                            bookingData={labtest}

                          />
                             <br /> 
                    <div><SubmitForm 
                         itemCount={cartItemCount}     
                         bookingData={labtest}                
                    />
                    </div>
                        </div>
                        
                          // <button onClick={() =>
                          //   dispatch({
                          //     type: "REMOVE_FROM_CART",
                          //     payload: labtest,
                          //   })
                          // } className='btn btn-danger brn-sm'>Remove</button>
                        ) : (

                          <button onClick={() =>
                            dispatch({
                              type: "ADD_TO_CART",
                              payload: labtest,
                            })

                          } class="btn btn-primary btn-sm">ADD TO CART</button>
                        )}
        <button type="button" class="btn btn-outline-primary btn-sm" data-bs-toggle="modal" data-bs-target="#exampleModal">
                          Enquiry
                        </button>


                        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h5 style={{ color: "Darkblue" }} class="modal-title" id="exampleModalLabel"><center>Evan Traders Pvt Ltd</center></h5>
                                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                              </div>
                              <div class="modal-body">
                                <AddBook />
                              </div>
                              <div class="modal-footer">
                                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              )
            })}

          </div>
          {/* <PaginationOld showPerPage={showPerPage}
            onPaginationChange={onPaginationChange}
            total={dalmias.length}
          /> */}
        </div>

      </div>




      {/* <div className="test2">
  <div className="card text-center" >
  <div className="card-body">
  <img src={Fever} class="card-img-top rounded-circle "alt="Fever"/>
  <br></br>
    <button type="button" class="btn btn-outline-primary">Fever</button>
    </div>
  </div>
</div> */}




{/* 
      <div className="album py-1">

        <div className="container">

        </div>
      </div> */}



      {/* <Checkup /> */}
      <br></br>





  
    </div>







  );
}

export default Dalmia;