import React from 'react'

function Map() {
  return (
    <div>
 <div className="album py-2">
        
        <div className="container">
 
        
        <div className="row">
<iframe  className='iframe-fluid'  height="385" frameborder="0" scrolling="no" marginheight="0" marginwidth="0" id="gmap_canvas" src="https://maps.google.com/maps?width=520&amp;height=400&amp;hl=en&amp;q=Evan%20Traders%20Pvt%20Ltd,%20Patta%20Buka%20Bridge,%20Domkal%20-%20Karimpur%20Rd,%20Karimpur,%20Thankar%20Pada,%20West%20Bengal%20741152%20+()&amp;t=&amp;z=12&amp;ie=UTF8&amp;iwloc=B&amp;output=embed"></iframe>
</div>
</div>
</div>

    </div>
  )
}

export default Map