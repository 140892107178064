import React from 'react';
import firebase from 'firebase/compat/app'
import 'firebase/compat/firestore'
import 'firebase/compat/auth'
import { db } from '../../firebase'
import { deleteDoc ,doc } from "firebase/firestore";

firebase.initializeApp({
  apiKey: "AIzaSyD_nM686a19pnwN4bxX8hmcbYV90Hz6OsI",
  authDomain: "evan-traders-94bc0.firebaseapp.com",
  projectId: "evan-traders-94bc0",
  storageBucket: "evan-traders-94bc0.appspot.com",
  messagingSenderId: "97420634105",
  appId: "1:97420634105:web:27298a5a3095c1eb733f28",
  measurementId: "G-XJ4SKCLDQ6"
})
const firestore = firebase.firestore()
const user = firebase.auth()
const booking = firestore.collection("Bookingsall")

function SubmitForm(data) {
    // console.log(itemCount, bookingData)
  const handleSubmit = async (event) => {
    event.preventDefault();
    // Perform actions when the form is submitted
    // You can submit data to a server, handle user input, etc.
    console.log(user.currentUser)
    if(!user.currentUser){
      window.location.href = '/login';
   }
   console.log( data)
    console.log(  {
    detail: data.bookingData.detail,
    phoneNumber: user.currentUser.phoneNumber ?? "",
    email: user.currentUser.email ?? "",
    uid: user.currentUser.uid,
    name: data.bookingData.name,
    price:data.bookingData.price,
    img:data.bookingData.img,
  })

    await booking.add(
      {
        detail: data.bookingData.detail,
        phoneNumber: user.currentUser.phoneNumber ?? "",
        email: user.currentUser.email ?? "",
        uid: user.currentUser.uid,
        name: data.bookingData.name,
        img: data.bookingData.img,
        price:data.bookingData.price * data.itemCount,
        itemCount:data.itemCount,
        bookingDone: false,
        paymentDone: false
      }
    ).then(()=>{
        console.log("booking submited successfully")
    })

  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Other form fields go here */}      
      {/* Submit button */}
      <button className='btn btn-primary btn-sm' type="submit">Submit</button>
    </form>
  );
}


function RemoveBooking(id) {
  // Define a function to be executed when the button is clicked
  const handleClick = async () => {
  console.log(id.id)
    const documentRef = doc(db, 'Bookingsall', id.id);

    try {
      await deleteDoc(documentRef);
      console.log('Document successfully deleted');
    } catch (error) {
      console.error('Error deleting document: ', error);
    }

  };
  return (
    <div>
      <button onClick={handleClick}className='btn btn-danger btn-sm'>Remove</button>
    </div>
  );
}


export  {SubmitForm,RemoveBooking};
