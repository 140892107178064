import React from 'react'
import poultry from "../images/poultry-evantraders.jpg";
import Duckfarm from "../images/duck-farm.jpg";
import Dairyfarm from "../images/diary.jpg";
import InovationAgriculture from "../images/agriculture.jpg";
import Fisharies from "../images/fisher.jpg";
import {Link} from "react-router-dom";


function Agripage() {
    return (
        <div>
            <div className="album py-0">

                <div className="container">

                    <div className="row">
                        <div className="col-md-4 mt-0 mb-4">
                            <div className="card" >
                               <Link to="/Poultry">
                                <img src={poultry} className="card-img-top" alt="evantraders_poultry" />
                                </Link>
                                <div className="card-body">
                                <center>
                                    <h5 style={{color:"maroon"}} className="card-title mb-2">Poultry</h5>
                                    <Link to="/Poultry">
                                    <button className="buttonfishari">LEARN MORE</button>
                                    </Link>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="card" >
                            <Link to="/Duckfarm">
                                <img src={Duckfarm } className="card-img-top" alt="evantraders-duckfarm" />
                                </Link>
                                <div className="card-body">
                                <center>
                                    <h5 style={{color:"maroon"}} className="card-title mb-2">Duck Farming</h5>
                                    <Link to="/Duckfarm">
                                    <button className="buttonfishari">LEARN MORE</button>
                                    </Link>
                                    </center>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div class="card" >
                                <Link to="/Dairy">
                                <img src={Dairyfarm} className="card-img-top" alt="evantraders_dairy" />
                                </Link>
                                <div class="card-body">
                                <center>
                                    <h5 style={{color:"maroon"}} className="card-title mb-2">Dairy</h5>
                                    <Link to="/Dairy">
                                    <button className="buttonfishari">LEARN MORE</button>
                                    </Link>
                                    </center>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4">
                            <div className="card" >
                                <Link to="/Fisheries">
                                <img src={Fisharies } className="card-img-top" alt="Fisharies-evantraders" />
                                </Link>
                                <div className="card-body">
                                    <center>
                                    <h5 style={{color:"maroon"}} className="card-title mb-2">Fisheries</h5>
                                    <Link to="/Fisheries">
                                    <button className="buttonfishari">LEARN MORE</button>
                                    </Link>
                                    </center>
                                </div>
                            </div>
                        </div>


                        <div className="col-md-4">
                            <div className="card" >
                                <Link to="/Agriculture">
                                <img src={InovationAgriculture } className="card-img-top" alt="InovationAgriculturekarimpur" />
                                </Link>
                                <div className="card-body">
                                <center>
                                    <h5 style={{color:"maroon"}} className="card-title mb-2">Innovation Agriculture</h5>
                                    <Link to="/Agriculture">
                                    <button className="buttonfishari">LEARN MORE</button>
                                    </Link>
                                    </center>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Agripage