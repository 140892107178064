
import React, { useEffect, useState, } from 'react'

import { Payment } from './payment';
import Navbar from '../inc/Navbar';
// import Uploadpres from '../pages/Uploadpres';
import { Badge, Dropdown, Container, Nav, Form, FormControl, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { storage } from '../../firebase';
import { ref, uploadBytes, listAll, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";

import { Button } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom'
import { useUserAuth } from '../../contexts/UserAuthContext'
import { collection, getDocs, getDoc, doc, query, where, onSnapshot, addDoc, arrayUnion, setDoc, updateDoc } from "firebase/firestore";
import { db } from '../../firebase'
import Formd from './Formd';
import { RemoveBooking } from './submit'
// import  DeliveryAddress from './showaddress'
// import Logo from '../images/logo4.png'


// import Logo2 from '../images/logo13.jpg';







function UserDashboard(props) {
  // const [selectedImage, setSelectedImage] = useState(null);
  const { logOut } = useUserAuth()
  const [photoURL, setPhotoURL] = useState()
  const navigate = useNavigate()
  const { user, state: { cart } } = useUserAuth();
  const userCollectionRef = collection(db, "newusers")
  const [labtests, setLabtests] = useState([])
  const labtestRef = collection(db, "Labtests")
  const [consults, setConsults] = useState([])
  const consultRef = collection(db, "Consults")
  const [checkups, setCheckups] = useState([])
  const checkupRef = collection(db, "Checkups")
  const [userinfo, setUserInfo] = useState([]);
  const [appointmentinfo, setAppointmentInfo] = useState([]);
  const [labtestbookinfo, setLabtestbookinfo] = useState([]);

  const [shippingform, setShippingform] = useState([]);

  const userLabtestBookingRef = collection(db, "Bookingsall")
  // const userBookingRef = collection(db, "AllBookings")
  const [consultbookinfo, setConsultbookinfo] = useState([]);
  const [checkupbookinfo, setCheckupbookinfo] = useState([]);

  const [payonline, setPayonline] = useState();

  const [userss, setUser] = useState([])

  const [imageUpload, setImageUpload] = useState(null);
  const [imageList, setImageList] = useState([]);

  useEffect(() => {
    const f = collection(db, "newusers");
    const pp = query(f, where("email", "==", user.email));
    onSnapshot(pp, (snapshot) => {
      let u = []
      snapshot.docs.forEach((doc) => {
        u.push({ ...doc.data(), id: doc.id })
      })
      // console.log(users)
      setUser(u)
    })
  }, []);
  var photoRef
  userss.map((uinfo) => {
    const f = doc(db, "newusers", uinfo.email)
    photoRef = f
  })
  let p = [];
  userinfo.map((url) => {
    p = url.records
  })

  // console.log(p)

  const imageListRef = ref(storage, "images3/")
  const uploadImage = () => {
    if (imageUpload == null) return;

    const imageRef = ref(storage, `images3/${imageUpload.name + v4()}`);
    uploadBytes(imageRef, imageUpload).then((snapshot) => {
      getDownloadURL(snapshot.ref).then((url) => {

        updateDoc(photoRef, {
          records: arrayUnion(url)
        });


        setImageList((prev) => [...prev, url])
        alert("Image Uploaded Successful");
      })

    });
  };

  useEffect(() => {
    listAll(imageListRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setImageList((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  // const q = query(userCollectionRef, where("email", "==", user.email));
  // const querySnapshot = getDocs(q);

  // if (!querySnapshot.empty){
  //   querySnapshot.forEach((doc) => {
  //     console.log(doc.id, " => ", doc.data());
  //   });
  // }


  useEffect(() => {
    const ref = collection(db, "newusers");
    const q = query(ref, where("email", "==", user.email));
    onSnapshot(q, (snapshot) => {
      let users = []
      snapshot.docs.forEach((doc) => {
        users.push({ ...doc.data(), id: doc.id })
      })
      // console.log(users)
      setUserInfo(users)
    })
  }, [])

  useEffect(() => {
    const newref = collection(db, "Appointments");
    const q = query(newref, where("email", "==", user.email));
    onSnapshot(q, (snapshot) => {
      let appointments = []
      snapshot.docs.forEach((doc) => {
        appointments.push({ ...doc.data(), id: doc.id })
      })

      setAppointmentInfo(appointments)
      //  console.log(appointments)
    })
  }, [])

  useEffect(() => {
    const ref = collection(db, "Bookingsall");
    const b = query(ref, where("email", "==", user.email));
    onSnapshot(b, (snapshot) => {
      let labtestbooking = []
      snapshot.docs.forEach((doc) => {
        labtestbooking.push({ ...doc.data(), id: doc.id })
      })
      // console.log(labtestbooking)
      setLabtestbookinfo(labtestbooking)
    })
  }, [])



  useEffect(() => {
    const ref = collection(db, "newusers");
    const b = query(ref, where("email", "==", user.email));
    onSnapshot(b, (snapshot) => {
      let shippingbooking = []
      snapshot.docs.forEach((doc) => {
        shippingbooking.push({ ...doc.data(), id: doc.id })
      })
      // console.log(shippingbooking)
      setShippingform(shippingbooking)
    })
  }, [])


  useEffect(() => {
    const ref = collection(db, "ConsultBookings");
    const g = query(ref, where("email", "==", user.email));
    onSnapshot(g, (snapshot) => {
      let consultbooking = []
      snapshot.docs.forEach((doc) => {
        consultbooking.push({ ...doc.data(), id: doc.id })
      })
      // console.log(consultbooking)
      setConsultbookinfo(consultbooking)
    })
  }, [])


  // useEffect(() => {
  //   const ref =collection(db,"AllBookings");
  //   const b = query(ref, where("email", "==", user.email), where("type", "==", "labtest"));
  //   onSnapshot(b,(snapshot) => {
  //     let labtestbooking=[]
  //     snapshot.docs.forEach((doc)=>{
  //       labtestbooking.push({...doc.data(),id:doc.id})
  //     })
  //     // console.log(labtestbooking)
  //     setLabtestbookinfo(labtestbooking)
  //   })
  // },[])

  // useEffect(() => {
  //   const ref =collection(db,"AllBookings");
  //   const g = query(ref, where("email", "==", user.email), where("type", "==", "consult"));
  //   onSnapshot(g,(snapshot) => {
  //     let consultbooking=[]
  //     snapshot.docs.forEach((doc)=>{
  //       consultbooking.push({...doc.data(),id:doc.id})
  //     })
  //     // console.log(consultbooking)
  //     setConsultbookinfo(consultbooking)
  //   })
  // },[])

  // useEffect(() => {
  //   const ref =collection(db,"AllBookings");
  //   const m = query(ref, where("email", "==", user.email), where("type", "==", "checkup"));
  //   onSnapshot(m,(snapshot) => {
  //     let checkupbooking=[]
  //     snapshot.docs.forEach((doc)=>{
  //       checkupbooking.push({...doc.data(),id:doc.id})
  //     })
  //     // console.log(consultbooking)
  //     setCheckupbookinfo(checkupbooking)
  //   })
  // },[])


  useEffect(() => {
    const ref = collection(db, "ConsultBookings");
    const g = query(ref, where("email", "==", user.email));
    onSnapshot(g, (snapshot) => {
      let consultbooking = []
      snapshot.docs.forEach((doc) => {
        consultbooking.push({ ...doc.data(), id: doc.id })
      })
      // console.log(consultbooking)
      setConsultbookinfo(consultbooking)
    })
  }, [])

  const [userr, setUserr] = useState()
  const params = useParams()
  useEffect(() => {
    getData()
  }, [])
  async function getData() {
    try {
      const userrTemp = await getDoc(doc(db, 'newusers', params.userId))
      setUserr(userrTemp.data())

    } catch (error) {
      console.log(error.message)
    }
  }

  const { dispatch } = useUserAuth();
  useEffect(() => {
    const getlabtests = async () => {
      const data = await getDocs(labtestRef);
      setLabtests(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    getlabtests()
  }, [])

  useEffect(() => {
    const getconsults = async () => {
      const data = await getDocs(consultRef);
      setConsults(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })))
    }
    getconsults()
  }, [])


  const [total, setTotal] = useState();

  useEffect(() => {
    setTotal(
      cart.reduce((acc, curr) => acc + Number(curr.price) * curr.qty, 0)
    );
  }, [cart]);


  //Razorpay integration
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script')
      script.src = src
      script.onload = () => {
        resolve(true)
      }
      script.onerror = () => {
        resolve(false)
      }
      document.body.appendChild(script)
    })
  }
  async function displayRazorpay(bookings) {
    console.log(bookings)
    const docRef = doc(db, 'yourCollection', 'yourDocumentId');
    // const res = await loadScript('https://checkout.razorpay.com/v1/checkout.js')

    // if (!res) {
    //   alert('Razorpay SDK failed to load. Are you online?')
    //   return
    // }

    const options = {
      // key: 'rzp_test_m1pxa5JKJbAV4p',
      key: 'rzp_test_7dtmMmVxRIkdxI',
      currency: 'INR',
      amount: total * 1,
      name: 'Evan Traders Pvt. Ltd',
      description: 'Razorpay Payment Gateway',
      image: "",
      handler: function (response) {
        alert(response.razorpay_payment_id)
        alert("Payment Succesfull")

        if (response.razorpay_payment_id) {
          cart.map((i) => {
            const usertestinfo = {
              "bookingDone": true,
              "paymentDone": true
            }
            // addDoc(userBookingRef, usertestinfo)
            updateDoc(userLabtestBookingRef, usertestinfo).then(() => {
              console.log("doc updated successfully")
            })
          })




        }


      },
      // if(response.razorpay_payment_id){

      // }
      prefill: {
        email: user.email,
      }
    }
    // const paymentObject = new window.Razorpay(options)
    // paymentObject.open()
  }
  const emailVerified = user.emailVerified;

  const handleLogOut = async () => {
    try {
      await logOut()
      window.location.reload()
      navigate("/")
    } catch (err) {

    }
  }



  return (
    <>

      <Navbar />
      <br />
      <br />
      <br />
      <br />

      <ul class="nav nav-pills mb-3 text-ligh shadow  " id="pills-tab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link active" id="pills-home-tab" data-bs-toggle="pill" data-bs-target="#pills-home" type="button" role="tab" aria-controls="pills-home" aria-selected="true">Dashborad</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-profile-tab" data-bs-toggle="pill" data-bs-target="#pills-profile" type="button" role="tab" aria-controls="pills-profile" aria-selected="false">My Personal Information </button>
        </li>

        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-contact-tab" data-bs-toggle="pill" data-bs-target="#pills-contact" type="button" role="tab" aria-controls="pills-contact" aria-selected="false">Upload Photo (Processing)</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-myappointment-tab" data-bs-toggle="pill" data-bs-target="#pills-myappointment" type="button" role="tab" aria-controls="pills-myappointment" aria-selected="false">My Order</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link" id="pills-numberupdate-tab" data-bs-toggle="pill" data-bs-target="#pills-numberupdate" type="button" role="tab" aria-controls="pills-numberupdate" aria-selected="false">Help Desk </button>
        </li>
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">

          <div className='userinfo'>

            {userinfo.map((uinfo) => {
              return (
                <div key={userinfo.id} style={{ margin: 30 }}>
                  <div className='welcomecolor'>
                  <h4> <center> Welcome to  {uinfo.fullname} <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
  <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z"/>
</svg></center></h4>
                  <h4><small>
                    <center>
                      Your email : {uinfo.email}
                    </center>
                    </small>
                  </h4>
                  <h4>
                    <center>
                      Your mobile : {uinfo.mobile}
                    </center>
                  </h4>
                  </div>
                </div>
              )
            })}
          </div>


          <h4 style={{ margin: 30, color: "maroon" }}>Your Orders</h4>

          {labtestbookinfo.length > 0 ? (
            <>
              {emailVerified ? (
                <div className="album py-1">

                  <div className="container">

                    <div className="row">
                      {labtestbookinfo.map((labinfo) => {
                        if (labinfo.bookingDone === false) {
                          return (
                            <div className="col-md-3" key={labinfo.id}>
                              <div className="card mb-4 border-dark box-shadow">
                                <img className="card-img-top" src={labinfo.img} alt={labinfo.name} />
                                <div className="card-body">

                                  <h5 style={{ color: "maroon" }} className="card-text">{labinfo.name}</h5>
                                  <p style={{ color: "Darkblue" }} className="card-text mb-2">{labinfo.detail}</p>

                                  <h6 style={{ color: "Darkblue" }} className="card-text mb-2">₹{labinfo.price}    </h6>
                                  {/* <h6 className="card-text">{labtest.type}  </h6> */}
                                  <div className="d-flex justify-content-between align-items-center">
                                    <RemoveBooking
                                      id={labinfo.id}
                                    />
                                    {/* <button  className='btn btn-danger btn-sm'>Remove</button> */}

                                  </div>
                                </div>
                              </div>

                            </div>



                          )
                        }
                      })}

                      {/* <AddLabbook/> */}

                    </div>

                  </div>

                  <div className="row">
                    {/* <div className="col-md-2"> */}
                    {/* <Formd />
                    </div>
                    <div className="col-md-2">
                    <DeliveryAddress />
                    </div> */}
                    <Formd />
                    {/* <DeliveryAddress /> */}
                  </div>

                  <div className='checkout' style={{ margin: 10, textAlign: "center" }}>
                    {/* <span style={{ fontWeight: 700, fontSize: 20 }}>Total: ₹ {total}</span> */}
                    <Payment
                      data={labtestbookinfo}
                      paymentType={payonline}
                    />
                    {/* <Button onClick={displayRazorpay(labtestbookinfo)} style={{ margin: 30 }} type="button" disabled={cart.length === 0}>
                      Proceed to Checkout
                    </Button> */}
                  </div>
                  <div className="album py-0">

                    <div className="container">

                      <div className="row">
                        <h3>{payonline}</h3>
                        <div className="col-md-2">
                          <input type='radio' name='payonline' value="Pay Online" onChange={e => setPayonline(e.target.value)} /> pay online
                        </div>
                        <div className="col-md-2">
                          <input type='radio' name='payonline' value="Cash On Delivery" onChange={e => setPayonline(e.target.value)} /> Cash

                        </div>


                        {/* <div className="col-md-2">
                    <input type="radio" id="html"  className="me-1" name="fav_language" value="HTML" />
                    <label for="html">Pay Online</label>
                    </div>
                    <div className="col-md-2">
                    <input type="radio" id="css" className="me-1" name="fav_language" value="CSS" />
                    <label for="css">Cash On Deliver</label>
                    </div> */}
                      </div>
                    </div>
                  </div>


                </div>

              ) : (
                <h3 style={{ display: "block", margin: 50, color: "maroon" }}>You Need to Verify your Email to see Your Orders</h3>
              )
              }

            </>



          ) : (
            <h3 style={{ margin: 30 }}>Cart is Empty!!!</h3>
          )}

          {emailVerified ? (

            <div className='right'>
              <h3 style={{ margin: 30, color: "Darkblue" }}>Yours Booking</h3>
              {appointmentinfo.map((ainfo) => {
                return (
                  <div key={appointmentinfo.id} style={{ margin: 30 }}>

                    <h3 style={{ margin: 30 }}>You Have Appointment With Dr. {ainfo.Appointment_with}</h3>
                    <h3 style={{ margin: 30 }}>At : {ainfo.Time} </h3>
                    <h3 style={{ margin: 30 }}>Date: {ainfo.Date}</h3>
                  </div>
                )
              })}


              <h2 style={{ margin: 30, color: "maroon" }}>Yours Products Bookings :- </h2>

              <div className="album py-3">
                <div className="container">
                  <div className="row" >
                    {labtestbookinfo.map((labinfo) => {
                      if (labinfo.bookingDone === true) {
                        return (
                          <div className="col-6" key={labinfo.id}>
                            <div className="card mb-4 border-success box shadow ">
                              <img height="100px" width="120px" src={labinfo.img} alt={labinfo.name} />
                              <div className="card-body">
                                <h5 style={{ color: "maroon" }} className="card-text">{labinfo.name}</h5>
                                <p className="card-text">{labinfo.detail}</p>
                                <h6 className="card-text">₹{labinfo.price}</h6>
                                <h6 className="card-text">{labinfo.type}</h6>
                                <div className="d-flex justify-content-between align-items-center">
                                </div>
                              </div>
                            </div>
                          </div>

                        )
                      }
                    })}
                  </div>
                </div>
              </div>


              <h2 style={{ margin: 30, color: "maroon" }}>Yours Anybook Bookings :- </h2>
              <div className="album py-3">
                <div className="container">
                  <div className="row" >
                    {consultbookinfo.map((consainfo) => {

                      return (
                        <div className="col-6" key={consainfo.id}>
                          <div className="card mb-4 border-primary box shadow ">

                            <div className="card-body">
                              <p className="card-text">{consainfo.detail}</p>
                              <h6 >₹{consainfo.price}</h6>

                            </div>
                          </div>
                        </div>

                      )
                    })}
                  </div>
                </div>

              </div>

              <div className="album py-3">
                <div className="container">
                  <div className="row" >
                    {checkupbookinfo.map((checkinfo) => {

                      return (
                        <div className="col-6" key={checkinfo.id}>
                          <div className="card mb-4 border-primary box shadow ">

                            <div className="card-body">
                              <p className="card-text">{checkinfo.detail}</p>
                              <h6 >₹{checkinfo.price}</h6>

                            </div>
                          </div>
                        </div>

                      )
                    })}
                  </div>
                </div>

              </div>

            </div>
          ) : (
            <div className='right'>
              <h2 style={{ display: "block", margin: 50, color: "darkgreen" }}>You Need to Verify your Email to see the Order</h2>

            </div>
          )
          }

        </div>




        <div class="tab-pane fade" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
          <div className='userinfo'>

            {userinfo.map((uinfo) => {
              return (
                <div key={userinfo.id} style={{ margin: 14 }}>
                  <h4> <center> Welcome  {uinfo.fullname} </center></h4>

                  <h5>
                    <center>
                      Your mobile : {uinfo.mobile}
                    </center>
                  </h5>
                </div>
              )
            })}
          </div>
          <h3 style={{ color: "purple", textAlign: "center" }}>My Personal Need Product Upload  <svg xmlns="http://www.w3.org/2000/svg" style={{color:"maroon"}} width="26" height="26"  fill="currentColor" class="bi bi-patch-check-fill" viewBox="0 0 16 16">
            <path d="M10.067.87a2.89 2.89 0 0 0-4.134 0l-.622.638-.89-.011a2.89 2.89 0 0 0-2.924 2.924l.01.89-.636.622a2.89 2.89 0 0 0 0 4.134l.637.622-.011.89a2.89 2.89 0 0 0 2.924 2.924l.89-.01.622.636a2.89 2.89 0 0 0 4.134 0l.622-.637.89.011a2.89 2.89 0 0 0 2.924-2.924l-.01-.89.636-.622a2.89 2.89 0 0 0 0-4.134l-.637-.622.011-.89a2.89 2.89 0 0 0-2.924-2.924l-.89.01-.622-.636zm.287 5.984-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L7 8.793l2.646-2.647a.5.5 0 0 1 .708.708z" />
          </svg></h3><br />
          {emailVerified ? (
            <div className="App">
              <input type="file" style={{ margin: 10 }} onChange={(event) => {
                setImageUpload(event.target.files[0])
              }} />
              <button onClick={uploadImage} class="btn btn-outline-success"> PRODUCT IMAGE</button><br /><br />
              {p.map((n) => {
                return (


                  <img src={n} style={{ margin: 15 }} class="img-fluid " width="200" height="260" alt=''></img>


                )
              })}


            </div>

          ) : (
            <div className='right'>
              <h4 style={{ display: "block", margin: 50, color: "purple" }}>You Need to Verify your Email to see the Order Product</h4>

            </div>
          )
          }


        </div>
        <br /><br />
        <div class="tab-pane fade" id="pills-contact" role="tabpanel" aria-labelledby="pills-contact-tab">

          {/* <Uploadpres/> */}

          {/* <h2 style={{margin: 50}}>Yours Consults Bookings :- </h2>
    <div className="album py-3"> 
      <div className="container">
        <div className="row" style={{margin:5}}>
            {consultbookinfo.map((consainfo) => { 
            
            return (
              <div className="col-6" key={consainfo.id}>
                <div className="card mb-2 border-dark box shadow">
                  
                    <div className="card-body">
                    
                      <h5 className="card-text">{consainfo.detail}</h5>
                      <h6 >₹{consainfo.price}</h6>
                      <h6 >{consainfo.type}</h6>
          
                    </div>
                  
                </div>
              </div>
            )})}
          </div>
      </div>
    </div>
            */}



          {/*   
  {/* <h4 style={{margin:30, color: "purple"}}>Your Orders</h4>
              
              {cart.length > 0 ?(
                <> 
                 {emailVerified ?(
                  <div className="album py-3">
          
                  <div className="container">
      
                      <div className="row">
                      {cart.map((consult) => { return (
                          <div className="col-md-3" key={consult.id}>
                            <div className="card mb-4 border-dark box-shadow">
                            <img className="card-img-top" src={consult.conImg} alt="doc1" />
                                <div className="card-body">
                                  <h5 className="card-text">{consult.name}</h5>
                                  <p className="card-text">{consult.detail}</p>
                                  <h6 className="card-text">₹{consult.price}</h6>
                                  <div className="d-flex justify-content-between align-items-center">
                               
                                  <button onClick={ () =>
                                    dispatch({
                                      type: "REMOVE_FROM_CART",
                                      payload: consult,
                                    })} className='btn btn-danger'>Remove</button>
                         
                                </div>
                           </div>
                           </div>
                         
                           </div>
                           
                         
                   
                        )})}
                        
                
                       
                        </div>
                        
                      </div>
  
                    
                   
                      <div className='checkout' style={{margin:10}}>
                        <span style={{ fontWeight: 700, fontSize: 20 }}>Total: ₹ {total}</span>
                        <Button  onClick={displayRazorpay} style={{margin:30}} type="button" disabled={cart.length === 0}>
                          Proceed to Checkout
                        </Button>
                      </div>
                  </div>
                  ):(
                    <h3 style={{ display: "block",margin:50 }}>You Need to Verify your Email to see Your Orders</h3>
                  )
                }
                
                </>
  
  
  
              ):(
                <h3 style={{margin:30}}>Cart is Empty!!!</h3>
              )} */}
          {/* </div> */}
          {/* {user.emailVerified ?(
            
              <div className='right'>
                <h3 style={{margin:30}}>Yours Appointments</h3>
                {appointmentinfo.map((ainfo) => { return (
                  <div key={appointmentinfo.id} style={{margin:30}}>
                    
                    <h3 style={{margin:30}}>You Have Appointment With Dr. {ainfo.Appointment_with}</h3>
                    <h3 style={{margin:30}}>At : {ainfo.Time} </h3>
                    <h3 style={{margin:30}}>Date: {ainfo.Date}</h3>
                  </div>
                )})}
              
                
              </div>
              ):(
                <div className='right'>
                <h2 style={{ display: "block",margin:50 }}>You Need to Verify your Email to see the Appointments</h2>
  
              </div>
              )
            // } */}
        </div>
        <div class="tab-pane fade" id="pills-myappointment" role="tabpanel" aria-labelledby="pills-myappointment-tab">
          <div className="text">
            <h2 style={{ margin: 5, color: "maroon", textAlign: "center" }}>Yours  Order :- </h2>
          </div>

          <div className="album py-3">
            <div className="container">
              <div className="row" >
                {labtestbookinfo.map((labinfo) => {

                  return (
                    <div className="col-6" key={labinfo.id}>
                      <div className="card mb-2 border-dark box shadow">

                        <div className="card-body">
                          <h5 className="card-text">{labinfo.name}</h5>
                          <p className="card-text">{labinfo.detail}</p>
                          <h6 className="card-text">₹{labinfo.price}</h6>
                          <h6 className="card-text">{labinfo.type}</h6>
                          <div className="d-flex justify-content-between align-items-center">
                          </div>
                        </div>
                      </div>
                    </div>

                  )
                })}
              </div>
            </div>
          </div>
        </div>
        <div class="tab-pane fade" id="pills-numberupdate" role="tabpanel" aria-labelledby="pills-numberupdate-tab">
          <div className="text">
            <h3 style={{ textAlign: "center" }}> Anywhere, At any time Email or call if you have a order related problem.</h3>
          </div>
                  <br/>

          {/* <div className="album py-5">
            <div className="container">
              <div className="row" >
                {shippingform.map((shipping) => {

                  return (
                    <div className="col-6" key={shipping.id}>
                      <div className="card mb-2 border-dark box shadow">

                        <div className="card-body">
                          <h5 className="card-text">{shipping.first}</h5>
                          <p className="card-text">{shipping.guardian}</p>
                          <h6 className="card-text">₹{shipping.price}</h6>
                          <h6 className="card-text">{shipping.type}</h6>
                          <div className="d-flex justify-content-between align-items-center">
                          </div>
                        </div>
                      </div>
                    </div>

                  )
                })}
              </div>
            </div>
          </div> */}
          <div className="album py-3">

            <div className="container">

              <div className="row">
                <div className="col-md-6 ">
                  <div className="card mb-4 box shadow">
                    <div className="card-body">
                      <a href='mailto: somporkobazaar@gmail.com'>
                        <h5 style={{ textAlign: "center" }}>Official Email Id: <a href="mailto: info@evantraders.net" className="btn btn-outline-primary">info@evantraders.net </a></h5>
                      </a>

                      <div className="d-flex justify-content-between align-items-center">


                      </div>

                    </div>


                  </div>


                </div>
                <div className="col-md-6">
                  <div className="card mb-4 box shadow">
                    <div className="card-body">

                      <h5 style={{ textAlign: "center" }}>Help Line Number <a href="tel:+91 9593481949" className="btn btn- btn-outline-">+91 9593481949</a></h5>
                      <div className="d-flex justify-content-between align-items-center">


                      </div>

                    </div>


                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>






      {/* <div className='doc-div' style={{ display: "flex", justifyContent: "space-between"}}>
          <div className='left'> */}





      {/* </div>
      
      </div> */}


      {/* 
      <div className='dashboard'>
        
    
        <h4 style={{textAlign: "center"}}> Welcome {user.email}</h4>
       
        <br></br> 

    

        <div className='doc-div' style={{ display: "flex", justifyContent: "space-between"}}>
          <div className='left'>
            <h4 style={{margin:30}}>Your Orders</h4>  
            {cart.length > 0 ?(
              <> 
                <div className='cart'>
                  <div className="labs">
                  {cart.map((labtest) => { return (
                      <div className="col" key={labtest.id}>
                        <div className="test1">
                          <div className="card text-center" >
                            <div className="card-body">
                              <h5 className="card-title">{labtest.name}</h5>
                              <p className="card-text">{labtest.detail}</p>
                              <h6 >₹{labtest.price}</h6>
                              <button onClick={ () =>
                                dispatch({
                                  type: "REMOVE_FROM_CART",
                                  payload: labtest,
                                })} className='btn btn-danger'>Remove</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    )})}
                    </div>
                    <div className='checkout' style={{margin:30}}>
                      <span style={{ fontWeight: 700, fontSize: 20 }}>Total: ₹ {total}</span>
                      <Button  onClick={displayRazorpay} style={{margin:30}} type="button" disabled={cart.length === 0}>
                        Proceed to Checkout
                      </Button>
                    </div>
                  
                </div>
                
              </>
            ):(
              <h2 style={{margin:30}}>Cart is Empty!!!</h2>
            )}
          </div>
          <div className='right'>
            <h4 style={{margin:30, color: "blue"}}>Yours Appointments</h4>

          </div>
        </div>

     
       


      
      </div>
     */}



    </>
  )
}

export default UserDashboard