import React from 'react'
import ReactPlayer from "react-player";

function HunumantaVideo() {
  return (
    <div>
        
        <div className="album py-2">
        
        <div className="container">
 
        
            <div className="row">
       
            <div className="col-md-4 mb-2">
          <ReactPlayer   width='340px' height='240px' url="https://www.youtube.com/watch?v=k9rHjtPuXvA"/>
          </div>
          <div className="col-md-4">
          <ReactPlayer   width='340px' height='240px' url="https://www.youtube.com/watch?v=BQAbAnaV-q4"/>
          </div>
        
          </div>
          </div>
          </div>
    </div>
  )
}

export default HunumantaVideo