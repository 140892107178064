
import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom';



import Home from "./components/pages/Home";
import Foodpage from "./components/pages/Foodpage";
import Contactus from "./components/pages/Contactus";
import Hunumanta from "./components/pages/Hunumanta";
import UserDashboard from './components/pages/UserDashboard';
import ProtectedRoute from './components/pages/ProtectedRoute';
import Footer from "./components/inc/Footer";
import UserAuthContextProvider from './contexts/UserAuthContext';
import firebase from 'firebase/compat/app'

import Login from './components/pages/Login';
import Signup from './components/pages/Signup';
import Tatapage from './components/pages/Tatapage';
import Dalmia from './components/pages/Dalmia';
import Poultry from './components/pages/Poultry';
import Terms from './components/pages/Terms';
import Refund from './components/pages/Refund';
import Privacy from './components/pages/Privacy';
import Shipping from './components/pages/Shipping';
import Duckfarm from './components/pages/Duck';
import Dairy from './components/pages/Dairy';
// import Agriculture from './components/pages/Agriculture';

import Unicare from './components/pages/Unicare';



firebase.initializeApp({
  apiKey: "AIzaSyD_nM686a19pnwN4bxX8hmcbYV90Hz6OsI",
  authDomain: "evan-traders-94bc0.firebaseapp.com",
  projectId: "evan-traders-94bc0",
  storageBucket: "evan-traders-94bc0.appspot.com",
  messagingSenderId: "97420634105",
  appId: "1:97420634105:web:27298a5a3095c1eb733f28",
  measurementId: "G-XJ4SKCLDQ6"
})

function App() {
  return (
  

      <Router>
         <UserAuthContextProvider>
    
       
          <div>
        
           
            
            <Routes>
       
           
            <Route axact path="/" element={<Home/>}/>
            <Route axact path="Dukes" element={<Foodpage/>}/>
            <Route axact path="/contact" element={<Contactus/>}/>
            <Route axact path="/login" element={<Login />} />
            <Route axact path="/signup" element={<Signup />} />
            <Route axact path="/Hunumanta" element={<Hunumanta />} />
            <Route axact path="/Dalmia" element={<Dalmia />} />
            <Route axact path="/Poultry" element={<Poultry />} />
            <Route axact path="/Duckfarm" element={<Duckfarm />} />
            <Route axact path="/Dairy" element={<Dairy />} />
            {/* <Route axact path="/Fisheries" element={<Fisheries />} /> */}
            {/* <Route axact path="/Agriculture" element={<Agriculture />} /> */}
            <Route axact path="/Terms" element={<Terms />} />
            <Route axact path="/refund" element={<Refund />} />
            <Route axact path="/Privacy" element={<Privacy />} />
            <Route axact path="/Shipping" element={<Shipping />} />
            <Route axact path="/Unicare" element={<Unicare/>} />
            
            <Route axact path="/tataproduct" element={<Tatapage />} />
            <Route axact path="/UserDashboard/:userId" element={<ProtectedRoute> <UserDashboard /> </ProtectedRoute>} />

            </Routes>
    <Footer/>
  
    </div>
    </UserAuthContextProvider>
    </Router>
  );
}

export default App;
