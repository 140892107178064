import React from 'react'
import Navbar from "../inc/Navbar";
import { Link } from "react-router-dom";
import uniacareproduct from "../images/product1.png"
import uniacareproduct2 from "../images/product2.jpg";
import Spray from "../images/SPRAY.jpg";
import uniacareproduct4 from "../images/product4.png";
import uniacareproduct5 from "../images/product5.jpg";
import uniacareproduct6 from "../images/senitizer.jpg";
import uniacareproduct7 from "../images/product7.png";
import uniacareproduct8 from "../images/p8.png";
import uniacareproduct9 from "../images/capuni.png";

import ScrollToTop from "react-scroll-to-top";
import "./Unicare.css";

function Unicare() {
    return (
        <div>
            <Navbar />
            <br />
            <br />


            <ScrollToTop smooth top="100" color="maroon" />
            <div className='colorback'>
                <br />
                <br />
                <div className="album py-3">

                    <div className="container">
                        <h3>UNICARE</h3>
                        <nav aria-label="breadcrumb" >
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/" style={{ color: "white" }} href="#">Home</Link></li>
                                <li style={{ color: "white" }} className="breadcrumb-item active" aria-current="page">Unicare</li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <br />
            <br />

            <center><h1><b>UNICARE BIOINTELLIGENT FORMULATIONS</b></h1></center>
            <br />
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">

                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={uniacareproduct} alt="unicare" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>
                                        {/* <img src={uniacareproduct} className="img-fluid rounded-start" alt="uniacareproduct" /> */}
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">UNICA REFILL(45ML)</h4>
                                            <p style={{ color: "grey" }} className="card-text texts">Unica is a 3rd gen BioIntelligent health care product formulated with essential oils and botanicals beneficial for overall health and wellness. Primarily its a Mosquito repellant with unique blend of natural and herbal active components, plus the health benefits of Neem, Tulsi, Eucalyptus, lemongrass oil, citronella, Lavender. Having herbal actives like Jensenenol and Citrenelol etc helps in soothing upper respiratory tract and viral infections..</p>
                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p style={{ color: "grey" }} className='texts mb-3'>Provides 100% preventive support against viral fever like dengue causing mosquito vectors. It’s validated for 97% repellency and 10% mortality against the mosquito species like Aedies Egypti etc</p>
                                            <p style={{ color: "grey" }} className='texts mb-3'>It is Non-toxic, stress reliever , also work on upper respiratory tract infections and viral infectionsThe essential oil fumes gives pleasant smell and reduces microbes in the surroundings</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={uniacareproduct2} alt="unicare" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">UNICA COMBO(Refill + Machine)</h4>
                                            <p style={{ color: "grey" }} className="card-text texts">Unica is a 3rd gen BioIntelligent health care product formulated with essential oils and botanicals beneficial for overall health and wellness. Primarily its a Mosquito repellant with unique blend of natural and herbal active components, plus the health benefits of Neem, Tulsi, Eucalyptus, lemongrass oil, citronella, Lavender. Having herbal actives like Jensenenol and Citrenelol etc helps in soothing upper respiratory tract and viral infections.</p>
                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p style={{ color: "grey" }} className='texts mb-3'>Provides 100% preventive support against viral fever like dengue causing mosquito vectors.
                                                It’s validated for 97% repellency and 10% mortality against the mosquito species like Aedies Egypti etc.
                                                It is Non-toxic, stress reliever , also work on upper respiratory tract infections and viral infections.
                                                The essential oil fumes gives pleasant smell and reduces microbes in the surroundings.</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={Spray} alt="spray" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>
                                        {/* <img src={Spray} className="img-fluid rounded-start" alt="evantraders" /> */}
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">UNICA INSECT SPRAY (200ml)</h4>
                                            <p style={{ color: "grey" }} className="card-text texts">Insects and mosquito repellent with naturally infused essential oil having herbal properties for Home, Cattles, Poultry , Pets , etc</p>
                                            <h4 style={{ color: "green" }} >Key Features:</h4>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <br />

            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={uniacareproduct4} alt="unicare" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">UNICA DISINFECTANT SPRAY (350ml)</h4>
                                            <p style={{ color: "grey" }} className="card-text texts">Unica is a 3rd gen BioIntelligent health care product formulated with essential oils and botanicals beneficial for overall health and wellness. Primarily its a Mosquito repellant with unique blend of natural and herbal active components, plus the health benefits of Neem, Tulsi, Eucalyptus, lemongrass oil, citronella, Lavender. Having herbal actives like Jensenenol and Citrenelol etc helps in soothing upper respiratory tract and viral infections.</p>
                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Natural Disinfectant spray</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Kills 99.9% of germs and provides protection against illness.</p>
                                            <p style={{ color: "grey" }} className='texts mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Eliminates odour</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">

                                        <div className="containerdeta">

                                            <img src={uniacareproduct5} alt="vegwash" className="image" />

                                            <div className="middle">

                                                <div className="text">UNICARE</div>

                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title mb-3">UNICA VEG WASH (200ml)</h4>
                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p>Fruits and Veggie wash</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Cleans wax, soils and pesticides</p>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={uniacareproduct6} alt="unicare" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">UNICA WISE HANDS SANITIZER (100ml, 200ml,500ml)</h4>

                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p>Kills 99.9% of all kind of germs</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg>For a quick and instant clean handsy</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> An effective antibacterial formula</p>
                                            <p style={{ color: "grey" }} className='texts mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Gives a soft moisturizing effect</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={uniacareproduct7} alt="unicare" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">Ethanol Based Hand Sanitizer SAFE HANDS</h4>

                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p> Kills 99.9% of all kind of germs</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg>For a quick and instant clean handsy</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> An effective antibacterial formula</p>
                                            <p style={{ color: "grey" }} className='texts mb-3'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Gives a soft moisturizing effect</p>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>

            <br />
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={uniacareproduct8} alt="unicare" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">DTOX 500</h4>
                                            <p className='texts'>A wellness and blood detoxification supplement consisting trace elements, minerals, botanicals and natural chelation agents. It is in the form of oral capsules which boosts the immune system, provides antioxidant effect, detoxification of blood and Micronutrients. This BioIntelligent product contains natural chelation agents and removes plasticizers and heavy metal toxicity.</p>

                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p> Rich in Micronutrients, vitamins, trace elements and minerals</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg>Immunity Booster</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Chelates toxins , heavy metals and plasticizers like BPA, PFOA etc from the blood</p>


                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
            <br/>
            <div className="album py-3">
                <div className="container">
                    <div className="row">
                        <a href='https://diponed.com/Unicare.aspx' target='_blank'>
                            <div className="card mb-3">
                                <div className="row g-0">
                                    <div className="col-md-4">
                                        <div className="containerdeta">
                                            <img src={uniacareproduct9} alt="unicare" className="image" />
                                            <div className="middle">
                                                <div className="text">UNICARE</div>
                                            </div>
                                        </div>

                                    </div>
                                    <div className="col-md-8">
                                        <div className="card-body">
                                            <h4 className="card-title">STEMED CAPSULES</h4>
                                            <p className='texts'>StemEd capsules helps in increased production and release of naturally occurring stem cells (like APSC and VSELs) in the body. It’s herbal BioIntelligent formulation, triggers the bone marrow to release more of its own stem cells into circulation. We have enhanced the power of StemEd by adding a proprietary mix of herbs and nutrients that improoves the microcirculation and helps in faster wound healing , antiaging and in fighting anemia in adults.</p>

                                            <h4 style={{ color: "green" }} >Key Features:</h4>
                                            <p>  Improves microcirculation, improoves neovascularization</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg>Fights Anaemia.</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg>  It helps in production and release of stem cells in the body.</p>
                                            <p style={{ color: "grey" }} className='texts mb-2'><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check-all" viewBox="0 0 16 16">
                                                <path d="M8.97 4.97a.75.75 0 0 1 1.07 1.05l-3.99 4.99a.75.75 0 0 1-1.08.02L2.324 8.384a.75.75 0 1 1 1.06-1.06l2.094 2.093L8.95 4.992a.252.252 0 0 1 .02-.022zm-.92 5.14.92.92a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 1 0-1.091-1.028L9.477 9.417l-.485-.486-.943 1.179z" />
                                            </svg> Supports treatment for anemias, fatigue, chronic diabetes, degenerative arthritis, peripheral neuropathies, and cardio vascular regeneration (CAD and Angina patients)</p>


                                        </div>
                                    </div>

                                    
                                </div>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Unicare
