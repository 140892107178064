import React from 'react'
import ReactPlayer from "react-player";

function DukesVideo() {
  return (
    <div>
        
        <div className="album py-2">
        
        <div className="container">
 
        
            <div className="row">
       
            <div className="col-md-4 mb-2">
          <ReactPlayer   width='344px' height='240px' url="https://www.youtube.com/watch?v=OQcRn7kZJVg"/>
          </div>
          <div className="col-md-4 mb-2">
          <ReactPlayer   width='344px' height='240px' url="https://www.youtube.com/watch?v=UfBojua737I"/>
          </div>
          <div className="col-md-4">
          <ReactPlayer   width='344px' height='240px' url="https://www.youtube.com/watch?v=nPjwsUmz9nI"/>
          </div>
          </div>
          </div>
          </div>
    </div>
  )
}

export default DukesVideo